v
<template>
  <v-sheet class="customer" id="customer" style="height: calc(100vh - 140px)">
    <v-row>
      <v-col md="6" class="my-auto">
        <h1 class="custom-header-blue-text m-0">Templates</h1>
      </v-col>
      <v-col md="6" class="text-right">
        <v-btn
          :disabled="pageLoading"
          :loading="pageLoading"
          class="white--text mt-1"
          depressed
          color="blue darken-4"
          tile
          @click="goToCreateTemplate"
        >
          Create Template
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-simple-table fixed-header height="72vh" class="bt-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th width="80">Action</th>
                <th width="350">Name</th>
                <th width="50">Template Category</th>
                <th width="50">Template Type</th>
                <th width="80">Created At</th>
                <th width="50">Status</th>
              </tr>
            </thead>
            <tbody v-if="!pageLoading">
              <template v-if="templateList.length">
                <tr @click="templateDetail(row.id)" v-for="(row, bkey) in templateList" :key="bkey">
                  <td>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-btn
                            v-on:click.stop.prevent="editTemplate(row)"
                            depressed
                            fab
                            :dark="row.disable_edit ? false : true"
                            x-small
                            color="blue darken-4 white--text"
                            :disabled="row.disable_edit ? 'disabled' : false"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </span>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-btn
                            depressed
                            fab
                            dark
                            class="ml-4"
                            x-small
                            color="red white--text"
                            v-on:click.stop.prevent="deleteConfirm(row)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </span>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </td>
                  <td width="100">
                    <ValueTemplate
                      v-model="row.template_name"
                      title="Template Name"
                    ></ValueTemplate>
                  </td>
                  <td width="200">
                    <Chip
                      v-if="row.category_name"
                      small
                      class="mr-2"
                      color="blue darken-4 text-capitalize"
                      :text="row.category_name"
                    ></Chip>
                    <template v-else
                      ><em class="text--secondary">No Template Category</em></template
                    >
                  </td>
                  <td width="200">
                    <ValueTemplate
                      class="text-capitalize"
                      v-model="row.template_type"
                      title="Template Type"
                    ></ValueTemplate>
                  </td>

                  <td width="200">
                    <ValueTemplate
                      v-model="row.added_at"
                      datetime
                      title="Created At"
                    ></ValueTemplate>
                  </td>
                  <td>
                    <Chip
                      small
                      :color="getStatusBGColor(row.status)"
                      class="mr-2 mb-2"
                      style="text-transform: uppercase"
                      :text="row.status"
                    ></Chip>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="9">
                    <p class="m-0 text-center">
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mr-4"
                      />
                      Uhh... There are no templates at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <tr v-for="idr in 9" :key="idr">
                <td v-for="idk in 9" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
        <template>
          <v-layout class="light-border-top mt-4">
            <v-flex md6>
              <label class="btx-label p-4">{{ showingString }}</label>
            </v-flex>
            <v-flex md6>
              <v-pagination
                color="blue darken-4"
                v-model="currentPage"
                :length="totalPages"
                :total-visible="7"
                v-on:input="getTemplate()"
              ></v-pagination>
            </v-flex>
          </v-layout>
        </template>
      </v-col>
    </v-row>
    <DeleteTemplate
      type="item"
      :delete-text="deleteText"
      delete-note="All transactions of this item will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="getTemplate()"
    ></DeleteTemplate>
    <CreateSegment
      v-if="generateSegmentDialog"
      :dialog="generateSegmentDialog"
      @close="generateSegmentDialog = false"
      @success="getTemplate()"
    />
  </v-sheet>
</template>

<script>
import CreateSegment from "@/view/module/marketing/CreateSegment";
import ValueTemplate from "@/view/components/ValueTemplate";
import Chip from "@/view/components/Chip";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import {
  GET_ALL_TEMPLATE,
  CHANGE_SEGMENTS_STATUS,
  DELETE_SEGMENTS,
  DELETE_TEMPLATE,
} from "@/core/lib/marketing.lib";
// import Dialog from "@/view/components/Dialog";
export default {
  name: "customer-create",
  title: "Create Customer",
  components: { ValueTemplate, Chip, CreateSegment, DeleteTemplate },
  data() {
    return {
      pageLoading: false,
      generateSegmentDialog: false,
      deleteDialog: false,
      deleteText: null,
      deleteId: null,
      deleteEndpoint: null,
      showingString: null,
      currentPage: 1,
      per_page: 1,
      loadPagination: false,
      totalPages: null,
      templateList: [],
    };
  },

  methods: {
    goToCreateTemplate() {
      this.$router.push({
        name: "create-template",
        query: { t: new Date().getTime() },
      });
    },
    editTemplate(row) {
      console.log(row);
      this.$router.push({
        name: "update-template",
        params: { id: row.id },
        query: { t: new Date().getTime() },
      });
    },
    templateDetail(id) {
      this.$router.push({
        name: "template-detail",
        params: { id: id },
        query: { t: new Date().getTime() },
      });
    },
    async deleteTemplate(id, type) {
      console.log("deleteTemplate");
      const _this = this;
      this.pageLoading = true;
      try {
        await DELETE_TEMPLATE(id, type ? type : "whatsapp");
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Template delete successfully." },
        ]);
        await this.getTemplate();
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.loadPagination = true;
      }
    },
    async getTemplate() {
      const _this = this;
      this.pageLoading = true;
      try {
        const { rows, totalPages } = await GET_ALL_TEMPLATE(this.currentPage);
        this.templateList = rows;
        this.totalPages = totalPages;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        // this.loadPagination = true;
      }
    },
    async segmentsDeleteSuccess() {
      await this.getTemplate();
      this.deleteDialog = false;
    },
    async deleteSegment() {
      const _this = this;
      const params = {
        selected: [_this.deleteId],
      };
      this.pageLoading = true;
      try {
        await DELETE_SEGMENTS(params);
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Segment delete successfully." },
        ]);
        await this.getTemplate();
        this.deleteDialog = false;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    async deleteConfirm(row) {
      this.deleteText = row.template_name;
      this.deleteEndpoint = `/whatsapp/template/delete?id=${row.id}&type=whatsapp`;
      this.deleteDialog = true;
    },
    async changeSegmentStatus(status, id) {
      const _this = this;
      const params = {
        action: status == 1 ? "active" : "inactive",
        selected: [id],
      };
      this.pageLoading = true;
      try {
        await CHANGE_SEGMENTS_STATUS(params);
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Segment Status Change Successfully." },
        ]);
        await this.getTemplate();
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    await this.getTemplate();
  },
};
</script>
